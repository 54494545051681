<template>
  <div class="home">
<!--    <el-checkbox v-model="queryParam.">-->
<!--    </el-checkbox>-->
    <div class="input-div">
      <el-input v-model="queryParam.universityName" class="input-class" size="large" placeholder="请输入学校模糊名称"
                @input="getUniversities">
        <template #prepend>
          <i class="el-icon-search"></i>
        </template>
        <template #append><span style="cursor: pointer;">搜索</span></template>
      </el-input>
    </div>

    <div class="input-div">
      <el-input v-model="queryParam.subjectName" class="input-class" size="large" placeholder="请输入专业模糊名称"
                @input="getUniversities">
        <template #prepend>
          <i class="el-icon-search"></i>
        </template>
        <template #append><span style="cursor: pointer;">搜索</span></template>
      </el-input>
    </div>


    <el-table :data="universityList"
              :style="'width: 100%'"
              height="850px"
              max-height="800px"
              border
              :row-style="modality"
              class="el-table--border">
      <el-table-column prop="universityName" label="学校名称" width="150" fixed></el-table-column>
      <el-table-column prop="universityCode" label="学校编码" width="100"></el-table-column>
      <el-table-column prop="academyAffiliation" label="学院归属" width="180"></el-table-column>
      <el-table-column prop="subjectName" label="专业名称" width="120"></el-table-column>
      <el-table-column prop="domainCode" label="学科代码" width="120"></el-table-column>
      <el-table-column prop="professionName" label="专业名称" width="250"></el-table-column>
      <el-table-column prop="researchDirection" label="研究方向" width="200"></el-table-column>
      <el-table-column label="考试科目" width="800">
        <template slot-scope="scope">
          <!-- 使用 scoped slot 来展示考试科目的详细内容 -->
          <el-table class="el-table--fluid-height" :data="[
            { name: '政治', exam: scope.row.politicsExamination, content: scope.row.politicsExamContent },
            { name: '英语', exam: scope.row.englishExamination, content: scope.row.englishExamContent },
            { name: '数学', exam: scope.row.businessDivisionA, content: scope.row.businessDivisionAContent },
            { name: '专业课1', exam: scope.row.businessDivisionB, content: scope.row.businessDivisionBContent },
            // { name: '专业课2', exam: scope.row.businessDivisionC, content: scope.row.businessDivisionCContent },
            // ... 可以继续添加其他科目
          ]" style="width: 100%">
            <el-table-column prop="name" label="科目"></el-table-column>
            <el-table-column prop="exam" label="内容"></el-table-column>
            <el-table-column label="详情">
              <template slot-scope="scope">
                <!-- 使用 v-html 渲染 Markdown，但请注意 XSS 攻击风险 -->
                <div v-if="scope.row.content" v-html="scope.row.content"></div>
                <!-- 或者使用 VueMarkdown 组件（如果已定义） -->
                <VueMarkdown v-else-if="scope.row.content" :content="scope.row.content"></VueMarkdown>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="admitPersonNum" label="招生人数(非推免)" width="100"></el-table-column>
      <el-table-column label="考试类型" width="100">
        <template slot-scope="scope">
          <!-- 这里可以使用自定义模板来展示考试内容 -->
          <p>{{ scope.row.examinationWay }}</p>
          <!-- 如果需要更复杂的展示，可以添加更多行或组件 -->
        </template>
      </el-table-column>
      <!-- 如果业务分部内容很多，也可以作为单独的列或子表格展示 -->
      <!-- ... 其他列定义 ... -->
    </el-table>

    <el-button type="text" @click="getMore">查看更多</el-button>
  </div>
</template>

<style scoped lang="less">


.el-table__body {
  overflow-y: auto;
}

.el-table__header {
  position: sticky;
  top: 0;
}

.font-obvious {
  color: black;
  font-weight: bolder;
}

.exam-card > p {
  display: inline-block;
  margin: 10px;
}

.each-container {
  margin-top: 50px;
  box-shadow: 0px 0px 73px -9px rgba(189, 172, 121, 0.44);
  -webkit-box-shadow: 0px 0px 73px -9px rgba(199, 186, 148, 0.44);
  -moz-box-shadow: 0px 0px 73px -9px rgba(124, 115, 89, 0.44);
}

// 设置表头的颜色
/deep/ .el-table thead tr > th {
  width: 100%;
  background-color: lightgray;
  border: none;
}

.input-div {
  width: 80%;
  border-radius: 120px !important;
  background-color: #fff;
  margin-top: 2%;
}

.input-class {

  .el-input-group__prepend {
    border-radius: 50% !important;
    border: 0;
    box-shadow: 0 0 0 0px;
  }

  .el-input__wrapper {
    border-radius: 50% !important;
    border: 0;
    box-shadow: 0 0 0 0px;
  }

  .el-input-group__append {
    border-radius: 50% !important;
    border: 0;
    box-shadow: 0 0 0 0px;
  }
}

/* 适用于小于600px宽度的设备（通常是移动设备） */
@media screen and (max-width: 600px) {
  /* 移动端样式 */
  .home {
    margin: 100px auto;
  }
}

/* 适用于大于或等于600px且小于900px宽度的设备 */
@media screen and (min-width: 600px) and (max-width: 899px) {
  /* 平板设备样式 */
}

/* 适用于大于或等于900px宽度的设备（通常是桌面电脑） */
@media screen and (min-width: 900px) {
  /* 桌面电脑样式 */
  .home {
    margin: 10% auto;
  }
}
</style>
<script>
import {getUniversity} from "@/api";

import VueMarkdown from "vue-markdown";

export default {
  data() {
    return {
      queryParam: {
        pageNum: 1,
        pageSize: 15,
        subjectName: "",
        universityName: ""
      },
      universityList: []
    }
  },
  components: {VueMarkdown},
  created() {
    this.getUniversities()
  },
  methods: {

    modality(row) {
      let admitPersonNum = row.row.admitPersonNum;
      // 通过id标识来改变当前行的文字颜色
      if (admitPersonNum >= 10 && admitPersonNum < 30) {
        return {
          color: "green"
        };
      }
      if (admitPersonNum >= 30 && admitPersonNum < 50) {
        return {
          color: "orange"
        };
      }
      if (admitPersonNum >= 50) {
        return {
          color: "red"
        };
      }
    },
    getUniversities() {
      getUniversity(this.queryParam).then(res => {
        if (res.code === 200) {
          this.universityList = res.data
        }
      })
    },
    getMore() {
      this.queryParam.pageNum += 1
      getUniversity(this.queryParam).then(res => {
        if (res.code === 200) {
          this.universityList = [...this.universityList, ...res.data]
        }
      })
    }
  }
}
</script>